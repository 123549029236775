import React, { useState, useEffect, useCallback } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ImageViewer from "./components/ImageViewer";
import EahaComponent from "./components/eaha/index";
import {
  CD_floorData,
  YLT_floorData,
  YDG_floorData,
  restroomIds,
} from "./floorData";

function App() {
  const [locations, setLocations] = useState([]);
  const [filteredLocations, setFilteredLocations] = useState([]);
  const [searchIds, setSearchIds] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const allLocations = await Promise.all(
        [...CD_floorData, ...YLT_floorData, ...YDG_floorData].map(
          async (floor) => {
            try {
              const response = await fetch(floor.jsonFile);
              if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
              }
              const annotations = await response.json();
              return { ...floor, annotations };
            } catch (error) {
              console.error(`Error fetching data for ${floor.floor}:`, error);
              return { ...floor, annotations: [] };
            }
          }
        )
      );
      setLocations(allLocations);
    };

    fetchData().catch((error) => console.error("Error fetching data:", error));
  }, []);
  const handleSearchByIds = useCallback(
    (ids) => {
      const specialLocations = [];

      const mapIds = [
        "ylt-en-map-1",
        "ylt-ja-map-1",
        "ylt-en-map-2",
        "ylt-ja-map-2",
        "cd-1f-en",
        "cd-1f-ja",
        "cd-2f-en",
        "cd-2f-ja",
        "cd-en-map",
        "cd-ja-map",
      ];
      mapIds.forEach((mapId) => {
        if (ids.includes(mapId)) {
          specialLocations.push({
            floor: "",
            imageFile: `/images/${mapId}.png`,
            annotations: [],
          });
        }
      });

      const filtered = locations
        .map((location) => {
          const floorRestroomIds = restroomIds[location.floor] || [];
          const matchedAnnotations = location.annotations.filter((annotation) =>
            ids.includes(annotation.id)
          );
          if (matchedAnnotations.length > 3) {
            const restroomAnnotations = location.annotations.filter(
              (annotation) => floorRestroomIds.includes(annotation.id)
            );
            return { ...location, annotations: restroomAnnotations };
          }
          if (matchedAnnotations.length > 0) {
            return { ...location, annotations: matchedAnnotations };
          }
          return null;
        })
        .filter((location) => location !== null);

      setFilteredLocations([...specialLocations, ...filtered]);
    },
    [locations]
  );
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const ids = params.get("ids");
    if (ids) {
      const idArray = ids.split(",");
      setSearchIds(idArray);
      handleSearchByIds(idArray);

      if (idArray.some((id) => id.startsWith("YLT"))) {
        document.title = "ルミネ有楽町";
      } else if (idArray.some((id) => id.startsWith("CD"))) {
        document.title = "ルミネ池袋";
      } else {
        document.title = "FKD宇都宮店";
      }
    }
  }, [locations, handleSearchByIds]);

  return (
    <Router>
      <div className="app-container">
        <Routes>
          <Route path="/eaha" element={<EahaComponent />} />
          <Route
            path="/"
            element={
              <div className="image-list">
                {filteredLocations.map((location) => (
                  <ImageViewer
                    key={location.floor}
                    location={location}
                    keywords={searchIds}
                  />
                ))}
              </div>
            }
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
