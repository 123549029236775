import React, { useState, useEffect, useRef, useCallback } from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import "./index.css";
import locations from "./locations.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearchPlus,
  faSearchMinus,
  faUndo,
} from "@fortawesome/free-solid-svg-icons";
function EahaComponent() {
  // 从URL中获取参数
  const urlParams = new URLSearchParams(window.location.search);
  const initialAmount = urlParams.get("amount") || "";
  const initialMakerCode = urlParams.get("makerCode") || "";
  const initialItemCode = urlParams.get("itemCode") || "";
  const initialMakerName = urlParams.get("makerName") || "";
  const showInputs = urlParams.get("showInputs") === "true"; // 控制所有输入字段的显隐

  const [amount, setAmount] = useState(initialAmount);
  const [makerCode, setMakerCode] = useState(initialMakerCode);
  const [itemCode, setItemCode] = useState(initialItemCode);
  const [makerName, setMakerName] = useState(initialMakerName);
  const canvasRef = useRef(null);
  const imageRef = useRef(null);

  const updateCanvas = useCallback(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.drawImage(imageRef.current, 0, 0, canvas.width, canvas.height);

    // 确保字体已加载
    document.fonts.ready.then(() => {
      // 绘制金额
      if (amount) {
        const formattedAmount = amount.padStart(8, " ");
        const amountGroups = [
          [
            "金額１千万",
            "金額１百万",
            "金額１十万",
            "金額１万",
            "金額１千",
            "金額１百",
            "金額１十",
            "金額１円",
          ],
          [
            "金額２千万",
            "金額２百万",
            "金額２十万",
            "金額２万",
            "金額２千",
            "金額２百",
            "金額２十",
            "金額２円",
          ],
          [
            "金額３千万",
            "金額３百万",
            "金額３十万",
            "金額３万",
            "金額３千",
            "金額３百",
            "金額３十",
            "金額３円",
          ],
        ];
        amountGroups.forEach((group) => {
          group.forEach((pos, index) => {
            const location = locations.find((loc) => loc.name === pos);
            if (location && index < formattedAmount.length) {
              const { x, y } = location;
              ctx.fillStyle = "red";
              ctx.font = "32px OCRB";
              ctx.fillText(formattedAmount[index], x - 15, y + 8);
            }
          });
        });
      }

      // 绘制制造商代码
      if (makerCode) {
        const codePositions = [
          "製造業者コード１桁目",
          "製造業者コード２桁目",
          "製造業者コード３桁目",
        ];
        codePositions.forEach((pos, index) => {
          const location = locations.find((loc) => loc.name === pos);
          if (location && index < makerCode.length) {
            const { x, y } = location;
            ctx.fillStyle = "red";
            ctx.font = "32px OCRB";
            ctx.fillText(makerCode[index], x - 15, y + 8);
          }
        });
      }

      // 绘制品目代码
      if (itemCode) {
        const itemCodePositions = ["品目コード１桁目", "品目コード２桁目"];
        itemCodePositions.forEach((pos, index) => {
          const location = locations.find((loc) => loc.name === pos);
          if (location && index < itemCode.length) {
            const { x, y } = location;
            ctx.fillStyle = "red";
            ctx.font = "32px OCRB";
            ctx.fillText(itemCode[index], x - 15, y + 8);
          }
        });
      }

      // 绘制制造商名称
      if (makerName) {
        const namePositions = ["通信欄１", "通信欄２"];
        namePositions.forEach((pos) => {
          const location = locations.find((loc) => loc.name === pos);
          if (location) {
            const { x, y } = location;
            ctx.fillStyle = "red";
            ctx.font = "28px OCRB";
            ctx.fillText(makerName, x - 15, y + 15);
          }
        });
      }
    });
  }, [amount, makerCode, itemCode, makerName]);

  useEffect(() => {
    const img = new Image();
    img.src = "/images/eaha/テンプレート.jpg";
    img.onload = () => {
      imageRef.current = img;
      document.fonts.ready.then(() => {
        requestAnimationFrame(updateCanvas); // 使用 requestAnimationFrame 进行重绘
      });
    };
  }, [updateCanvas]);

  useEffect(() => {
    document.title = "郵便リサイクル";
  }, []);

  const handleInput = (setter) => (event) => {
    setter(event.target.value);
    updateCanvas();
  };

  return (
    <div>
      {showInputs && (
        <>
          <div>
            <label>金额：</label>
            <input
              type="text"
              value={amount}
              onChange={handleInput(setAmount)}
            />
          </div>
          <div>
            <label>制造商代码：</label>
            <input
              type="text"
              value={makerCode}
              onChange={handleInput(setMakerCode)}
            />
          </div>
          <div>
            <label>品目代码：</label>
            <input
              type="text"
              value={itemCode}
              onChange={handleInput(setItemCode)}
            />
          </div>
          <div>
            <label>制造商名称：</label>
            <input
              type="text"
              value={makerName}
              onChange={handleInput(setMakerName)}
            />
          </div>
        </>
      )}
      <TransformWrapper
        defaultScale={1}
        wheel={{ disabled: true }}
        doubleClick={{ disabled: true }}
        options={{
          limitToBounds: false,
          centerContent: false,
        }}
        pan={{
          paddingSize: 500,
        }}
        zoomIn={{
          step: 0.1,
        }}
        zoomOut={{
          step: 0.1,
        }}
        initialScale={0.9}
        minScale={0.5}
        maxScale={2}
        centerOnInit={false}
      >
        {({ zoomIn, zoomOut, resetTransform }) => (
          <>
            <div className="controls">
              <button
                onClick={() => {
                  zoomIn();
                }}
              >
                <FontAwesomeIcon icon={faSearchPlus} />
              </button>
              <button
                onClick={() => {
                  zoomOut();
                }}
              >
                <FontAwesomeIcon icon={faSearchMinus} />
              </button>
              <button
                onClick={() => {
                  resetTransform();
                }}
              >
                <FontAwesomeIcon icon={faUndo} />
              </button>
            </div>
            <TransformComponent>
              <canvas
                ref={canvasRef}
                width="1497"
                height="1773"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                }}
              ></canvas>
            </TransformComponent>
          </>
        )}
      </TransformWrapper>
    </div>
  );
}

export default EahaComponent;
